import { _reducer as listReducer} from './slices/list'
import { _reducer as deleteReducer} from './slices/delete'
import { _reducer as editReducer} from './slices/edit'
import { _reducer as listForSelectReducer} from './slices/listForSelect'

export const brandsReducers = {
  ...listReducer,
  ...deleteReducer,
  ...editReducer,
  ...listForSelectReducer,
}

export {
  fetchDispatcher as fetchBrands,
  useSelector as useBrandsState,
  actions as brandsActions
} from './slices/list'

export {
  useSelector as useBrandsEditState,
  fetchDispatcher as fetchBrandForEdit,
  resetDispatcher as resetBrandForEdit,
  saveDispatcher as saveBrand
} from './slices/edit'

export {
  useSelector as useBrandsDeleteState,
  fetchDispatcher as fetchBrandForDelete,
  deleteDispatcher as deleteBrand
} from './slices/delete'

export {
  useSelector as useBrandsForSelect,
  fetchDispatcher as fetchBrandsForSelect,
  actions as brandsForSelectActions
} from './slices/listForSelect'
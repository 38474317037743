import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {
    AEAutocompleteField,
    AEDatePickerField,
    AEField,
    AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {localField} from "../../../../../_ae/helpers/UIHelper";
import {InputAdornment} from "@material-ui/core";
import {fetchProductForDetail, resetProductForDetail, useProductsDetailState} from "../../../../../redux/products";
import {ProductField} from "../../../Products";
import {FieldArray} from "formik";
import {AEButton} from "../../../../../_ae/components/buttons";
// import {defaultStockOrderLine} from "../../../../../redux/stockOrders/slices/edit";
import {AEIcon, ICONS} from "../../../../../_ae/components/svg";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
// import {VariantField} from "../../index";
import {defaultPrice} from "../../../../../redux/variants/slices/edit";

export const Form = ({values, handleSubmit, setFieldValue, btnRef}) => {
    const {data: product} = useProductsDetailState(); // todo
    const dispatch = useDispatch();


    useEffect(() => {
        setFieldValue('product', product.id ? product : values.product)
    }, [product])

    return (
        <div className="form form-label-right">
            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEFileField
                        multiple
                        name={'images'}
                        label={'IMAGES'}
                        // preview
                        previewPath={'/variant_images'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg"}>
                    <ProductField
                        disabled
                    />
                </div>

                <div className={"col-lg"}>
                    <AEField
                        name="nameFr"
                        label={'NAME'}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEField
                        name="shippingDelay"
                        label={'SHIPPING_DELAY'}
                        type={'number'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <FormattedMessage id={'HOURS'}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className={"col-lg"}>
                    <AEField
                        name="price"
                        label={'PRICE'}
                        type={'number'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <FormattedMessage id={'MAD'}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>

            {
                values.product && values.product.attributes.length > 0 &&
                <div className="form-group row">
                    {
                        values.product.attributes.map((attribute, index) => (
                            <div key={attribute.id} className={"col-lg"}>
                                <AEAutocompleteField
                                    name={`options.${index}`}
                                    label={attribute[localField()]}
                                    options={attribute.options}
                                    getOptionLabel={o => o.value}
                                    useI18n={false}
                                />
                            </div>
                        ))
                    }
                </div>
            }


            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEField
                        type={'textarea'}
                        rows={5}
                        name="descFr"
                        label={'DESCRIPTION'}
                    />
                </div>
            </div>

            <div className="form-group">
                <FieldArray
                    name="prices"
                >
                    {
                        (helpers) => (
                            <div>
                                <div className={'d-flex align-items-center form-group'}>
                  <span className="font-size-h3 font-weight-bold">
                    <FormattedMessage id={'PRICES'}/>
                  </span>
                                    <AEButton
                                        variant={'clean'}
                                        icon
                                        onClick={() => {
                                            helpers.push(defaultPrice)
                                        }}
                                    >
                                        <AEIcon
                                            variant={'primary'}
                                            path={ICONS.PLUS}
                                            size={'lg'}
                                        />
                                    </AEButton>
                                </div>
                                {
                                    // !routeLoading &&
                                    values.prices.map((price, index) => (
                                        <Card key={index} className={'card-border'}>
                                            <CardBody className={'pb-0'}>
                                                <div
                                                    className="d-flex justify-content-between align-items-center gutter-b">
                                                    <div className='font-weight-bold font-size-h5'>
                                                        <FormattedMessage id={'QUANTITY'}/>
                                                        {/*{` : ${index+1}`}*/}
                                                        {` : ${price.minQty} - ${price.maxQty}`}
                                                    </div>
                                                    <AEButton
                                                        variant={'light-danger'}
                                                        size={'sm'}
                                                        onClick={() => {
                                                            helpers.remove(index)
                                                        }}
                                                    >
                                                        <AEIcon
                                                            // variant={'danger'}
                                                            path={ICONS.DELETE}
                                                            size={'sm'}
                                                        />
                                                        <FormattedMessage id={'DELETE'}/>
                                                    </AEButton>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="col-md form-group">
                                                        <AEField
                                                            name={`prices.${index}.price`}
                                                            label={'PRICE'}
                                                            type={'number'}
                                                        />
                                                    </div>
                                                    <div className="col-md form-group">
                                                        <AEField
                                                            name={`prices.${index}.minQty`}
                                                            label={'MIN_QTY'}
                                                            type={'number'}
                                                        />
                                                    </div>
                                                    <div className="col-md form-group">
                                                        <AEField
                                                            name={`prices.${index}.maxQty`}
                                                            label={'MAX_QTY'}
                                                            type={'number'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="col-md form-group">
                                                        <AEDatePickerField
                                                            name={`prices.${index}.startAt`}
                                                            label={'START_DATE'}
                                                        />
                                                    </div>
                                                    <div className="col-md form-group">
                                                        <AEDatePickerField
                                                            name={`prices.${index}.endAt`}
                                                            label={'END_DATE'}
                                                        />
                                                    </div>
                                                </div>

                                            </CardBody>
                                        </Card>
                                    ))
                                }
                            </div>
                        )
                    }
                </FieldArray>
            </div>


            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}


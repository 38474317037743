import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {useIntl} from "react-intl";
import {AEAutocompleteField, AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {localField} from "../../../../../_ae/helpers/UIHelper";

export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();

  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg"}>
          <AEField
            name="nameFr"
            label={'NAME'}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={"col-lg"}>
          <AEField
            type={'textarea'}
            rows={5}
            name="descFr"
            label={'DESCRIPTION'}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={"col-lg"}>
          <AEFileField
            name="fileName"
            label={'LOGO'}
            preview
            previewPath={'/brands'}
          />
        </div>
      </div>
      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

